import { SelectOption } from '@modules/forms/components/SelectField'

export const COUNTRIES: SelectOption[] = [
  { name: '', value: '' },
  { name: 'United States', value: 'United States', privacyPolicy: false },
  { name: 'Canada', value: 'Canada', privacyPolicy: false },
  { name: 'Afghanistan', value: 'Afghanistan', privacyPolicy: false },
  { name: 'Aland Islands', value: 'Aland Islands', privacyPolicy: false },
  { name: 'Albania', value: 'Albania', privacyPolicy: true },
  { name: 'Algeria', value: 'Algeria', privacyPolicy: false },
  { name: 'Andorra', value: 'Andorra', privacyPolicy: true },
  { name: 'Angola', value: 'Angola', privacyPolicy: false },
  { name: 'Anguilla', value: 'Anguilla', privacyPolicy: false },
  { name: 'Antarctica', value: 'Antarctica', privacyPolicy: false },
  {
    name: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
    privacyPolicy: false
  },
  { name: 'Argentina', value: 'Argentina', privacyPolicy: false },
  { name: 'Armenia', value: 'Armenia', privacyPolicy: true },
  { name: 'Aruba', value: 'Aruba', privacyPolicy: false },
  { name: 'Australia', value: 'Australia', privacyPolicy: false },
  { name: 'Austria', value: 'Austria', privacyPolicy: true },
  { name: 'Azerbaijan', value: 'Azerbaijan', privacyPolicy: true },
  { name: 'Bahamas', value: 'Bahamas', privacyPolicy: false },
  { name: 'Bahrain', value: 'Bahrain', privacyPolicy: false },
  { name: 'Bangladesh', value: 'Bangladesh', privacyPolicy: false },
  { name: 'Barbados', value: 'Barbados', privacyPolicy: false },
  { name: 'Belarus', value: 'Belarus', privacyPolicy: true },
  { name: 'Belgium', value: 'Belgium', privacyPolicy: true },
  { name: 'Belize', value: 'Belize', privacyPolicy: false },
  { name: 'Benin', value: 'Benin', privacyPolicy: false },
  { name: 'Bermuda', value: 'Bermuda', privacyPolicy: false },
  { name: 'Bhutan', value: 'Bhutan', privacyPolicy: false },
  {
    name: 'Bolivia, Plurinational State of',
    value: 'Bolivia, Plurinational State of',
    privacyPolicy: false
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
    privacyPolicy: false
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
    privacyPolicy: true
  },
  { name: 'Botswana', value: 'Botswana', privacyPolicy: false },
  { name: 'Bouvet Island', value: 'Bouvet Island', privacyPolicy: false },
  { name: 'Brazil', value: 'Brazil', privacyPolicy: false },
  {
    name: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
    privacyPolicy: false
  },
  {
    name: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
    privacyPolicy: false
  },
  { name: 'Bulgaria', value: 'Bulgaria', privacyPolicy: true },
  { name: 'Burkina Faso', value: 'Burkina Faso', privacyPolicy: false },
  { name: 'Burundi', value: 'Burundi', privacyPolicy: false },
  { name: 'Cambodia', value: 'Cambodia', privacyPolicy: false },
  { name: 'Cameroon', value: 'Cameroon', privacyPolicy: false },
  { name: 'Cape Verde', value: 'Cape Verde', privacyPolicy: false },
  { name: 'Cayman Islands', value: 'Cayman Islands', privacyPolicy: false },
  {
    name: 'Central African Republic',
    value: 'Central African Republic',
    privacyPolicy: false
  },
  { name: 'Chad', value: 'Chad', privacyPolicy: false },
  { name: 'Chile', value: 'Chile', privacyPolicy: false },
  { name: 'China', value: 'China', privacyPolicy: false },
  { name: 'Chinese Taipei', value: 'Chinese Taipei', privacyPolicy: false },
  { name: 'Christmas Island', value: 'Christmas Island', privacyPolicy: false },
  {
    name: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
    privacyPolicy: false
  },
  { name: 'Colombia', value: 'Colombia', privacyPolicy: false },
  { name: 'Comoros', value: 'Comoros', privacyPolicy: false },
  { name: 'Congo', value: 'Congo', privacyPolicy: false },
  {
    name: 'Congo, the Democratic Republic of the',
    value: 'Congo, the Democratic Republic of the',
    privacyPolicy: false
  },
  { name: 'Cook Islands', value: 'Cook Islands', privacyPolicy: false },
  { name: 'Costa Rica', value: 'Costa Rica', privacyPolicy: false },
  { name: "Cote d'Ivoire", value: "Cote d'Ivoire", privacyPolicy: false },
  { name: 'Croatia', value: 'Croatia', privacyPolicy: true },
  { name: 'Cuba', value: 'Cuba', privacyPolicy: false },
  { name: 'Curaçao', value: 'Curaçao', privacyPolicy: false },
  { name: 'Cyprus', value: 'Cyprus', privacyPolicy: true },
  { name: 'Czech Republic', value: 'Czech Republic', privacyPolicy: true },
  { name: 'Denmark', value: 'Denmark', privacyPolicy: true },
  { name: 'Djibouti', value: 'Djibouti', privacyPolicy: false },
  { name: 'Dominica', value: 'Dominica', privacyPolicy: false },
  {
    name: 'Dominican Republic',
    value: 'Dominican Republic',
    privacyPolicy: false
  },
  { name: 'Ecuador', value: 'Ecuador', privacyPolicy: false },
  { name: 'Egypt', value: 'Egypt', privacyPolicy: false },
  { name: 'El Salvador', value: 'El Salvador', privacyPolicy: false },
  {
    name: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
    privacyPolicy: false
  },
  { name: 'Eritrea', value: 'Eritrea', privacyPolicy: false },
  { name: 'Estonia', value: 'Estonia', privacyPolicy: true },
  { name: 'Ethiopia', value: 'Ethiopia', privacyPolicy: false },
  {
    name: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
    privacyPolicy: false
  },
  { name: 'Faroe Islands', value: 'Faroe Islands', privacyPolicy: false },
  { name: 'Fiji', value: 'Fiji', privacyPolicy: false },
  { name: 'Finland', value: 'Finland', privacyPolicy: true },
  { name: 'France', value: 'France', privacyPolicy: true },
  { name: 'French Guiana', value: 'French Guiana', privacyPolicy: false },
  { name: 'French Polynesia', value: 'French Polynesia', privacyPolicy: false },
  {
    name: 'French Southern Territories',
    value: 'French Southern Territories',
    privacyPolicy: false
  },
  { name: 'Gabon', value: 'Gabon', privacyPolicy: false },
  { name: 'Gambia', value: 'Gambia', privacyPolicy: false },
  { name: 'Georgia', value: 'Georgia', privacyPolicy: true },
  { name: 'Germany', value: 'Germany', privacyPolicy: true },
  { name: 'Ghana', value: 'Ghana', privacyPolicy: false },
  { name: 'Gibraltar', value: 'Gibraltar', privacyPolicy: false },
  { name: 'Greece', value: 'Greece', privacyPolicy: true },
  { name: 'Greenland', value: 'Greenland', privacyPolicy: true },
  { name: 'Grenada', value: 'Grenada', privacyPolicy: false },
  { name: 'Guadeloupe', value: 'Guadeloupe', privacyPolicy: false },
  { name: 'Guatemala', value: 'Guatemala', privacyPolicy: false },
  { name: 'Guernsey', value: 'Guernsey', privacyPolicy: false },
  { name: 'Guinea', value: 'Guinea', privacyPolicy: false },
  { name: 'Guinea-Bissau', value: 'Guinea-Bissau', privacyPolicy: false },
  { name: 'Guyana', value: 'Guyana', privacyPolicy: false },
  { name: 'Haiti', value: 'Haiti', privacyPolicy: false },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
    privacyPolicy: false
  },
  {
    name: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
    privacyPolicy: false
  },
  { name: 'Honduras', value: 'Honduras', privacyPolicy: false },
  { name: 'Hungary', value: 'Hungary', privacyPolicy: true },
  { name: 'Iceland', value: 'Iceland', privacyPolicy: true },
  { name: 'India', value: 'India', privacyPolicy: false },
  { name: 'Indonesia', value: 'Indonesia', privacyPolicy: false },
  {
    name: 'Iran, Islamic Republic of',
    value: 'Iran, Islamic Republic of',
    privacyPolicy: false
  },
  { name: 'Iraq', value: 'Iraq', privacyPolicy: false },
  { name: 'Ireland', value: 'Ireland', privacyPolicy: true },
  { name: 'Isle of Man', value: 'Isle of Man', privacyPolicy: false },
  { name: 'Israel', value: 'Israel', privacyPolicy: false },
  { name: 'Italy', value: 'Italy', privacyPolicy: true },
  { name: 'Jamaica', value: 'Jamaica', privacyPolicy: false },
  { name: 'Japan', value: 'Japan', privacyPolicy: false },
  { name: 'Jersey', value: 'Jersey', privacyPolicy: false },
  { name: 'Jordan', value: 'Jordan', privacyPolicy: false },
  { name: 'Kazakhstan', value: 'Kazakhstan', privacyPolicy: true },
  { name: 'Kenya', value: 'Kenya', privacyPolicy: false },
  { name: 'Kiribati', value: 'Kiribati', privacyPolicy: false },
  {
    name: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of",
    privacyPolicy: false
  },
  {
    name: 'Korea, Republic of',
    value: 'Korea, Republic of',
    privacyPolicy: false
  },
  { name: 'Kuwait', value: 'Kuwait', privacyPolicy: false },
  { name: 'Kyrgyzstan', value: 'Kyrgyzstan', privacyPolicy: false },
  {
    name: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
    privacyPolicy: false
  },
  { name: 'Latvia', value: 'Latvia', privacyPolicy: true },
  { name: 'Lebanon', value: 'Lebanon', privacyPolicy: false },
  { name: 'Lesotho', value: 'Lesotho', privacyPolicy: false },
  { name: 'Liberia', value: 'Liberia', privacyPolicy: false },
  {
    name: 'Libyan Arab Jamahiriya',
    value: 'Libyan Arab Jamahiriya',
    privacyPolicy: false
  },
  { name: 'Liechtenstein', value: 'Liechtenstein', privacyPolicy: true },
  { name: 'Lithuania', value: 'Lithuania', privacyPolicy: true },
  { name: 'Luxembourg', value: 'Luxembourg', privacyPolicy: true },
  { name: 'Macao', value: 'Macao', privacyPolicy: false },
  {
    name: 'Macedonia, the former Yugoslav Republic of',
    value: 'Macedonia, the former Yugoslav Republic of',
    privacyPolicy: false
  },
  { name: 'Madagascar', value: 'Madagascar', privacyPolicy: false },
  { name: 'Malawi', value: 'Malawi', privacyPolicy: false },
  { name: 'Malaysia', value: 'Malaysia', privacyPolicy: false },
  { name: 'Maldives', value: 'Maldives', privacyPolicy: false },
  { name: 'Mali', value: 'Mali', privacyPolicy: false },
  { name: 'Malta', value: 'Malta', privacyPolicy: true },
  { name: 'Martinique', value: 'Martinique', privacyPolicy: false },
  { name: 'Mauritania', value: 'Mauritania', privacyPolicy: false },
  { name: 'Mauritius', value: 'Mauritius', privacyPolicy: false },
  { name: 'Mayotte', value: 'Mayotte', privacyPolicy: false },
  { name: 'Mexico', value: 'Mexico', privacyPolicy: false },
  {
    name: 'Moldova, Republic of',
    value: 'Moldova, Republic of',
    privacyPolicy: true
  },
  { name: 'Monaco', value: 'Monaco', privacyPolicy: true },
  { name: 'Mongolia', value: 'Mongolia', privacyPolicy: false },
  { name: 'Montenegro', value: 'Montenegro', privacyPolicy: true },
  { name: 'Montserrat', value: 'Montserrat', privacyPolicy: false },
  { name: 'Morocco', value: 'Morocco', privacyPolicy: false },
  { name: 'Mozambique', value: 'Mozambique', privacyPolicy: false },
  { name: 'Myanmar', value: 'Myanmar', privacyPolicy: false },
  { name: 'Namibia', value: 'Namibia', privacyPolicy: false },
  { name: 'Nauru', value: 'Nauru', privacyPolicy: false },
  { name: 'Nepal', value: 'Nepal', privacyPolicy: false },
  { name: 'Netherlands', value: 'Netherlands', privacyPolicy: true },
  { name: 'New Caledonia', value: 'New Caledonia', privacyPolicy: true },
  { name: 'New Zealand', value: 'New Zealand', privacyPolicy: false },
  { name: 'Nicaragua', value: 'Nicaragua', privacyPolicy: false },
  { name: 'Niger', value: 'Niger', privacyPolicy: false },
  { name: 'Nigeria', value: 'Nigeria', privacyPolicy: false },
  { name: 'Niue', value: 'Niue', privacyPolicy: false },
  { name: 'Norfolk Island', value: 'Norfolk Island', privacyPolicy: false },
  { name: 'Norway', value: 'Norway', privacyPolicy: true },
  { name: 'Oman', value: 'Oman', privacyPolicy: false },
  { name: 'Pakistan', value: 'Pakistan', privacyPolicy: false },
  {
    name: 'Palestinian Territory, Occupied',
    value: 'Palestinian Territory, Occupied',
    privacyPolicy: false
  },
  { name: 'Panama', value: 'Panama', privacyPolicy: false },
  { name: 'Papua New Guinea', value: 'Papua New Guinea', privacyPolicy: false },
  { name: 'Paraguay', value: 'Paraguay', privacyPolicy: false },
  { name: 'Peru', value: 'Peru', privacyPolicy: false },
  { name: 'Philippines', value: 'Philippines', privacyPolicy: false },
  { name: 'Pitcairn', value: 'Pitcairn', privacyPolicy: false },
  { name: 'Poland', value: 'Poland', privacyPolicy: true },
  { name: 'Portugal', value: 'Portugal', privacyPolicy: true },
  { name: 'Qatar', value: 'Qatar', privacyPolicy: false },
  { name: 'Reunion', value: 'Reunion', privacyPolicy: false },
  { name: 'Romania', value: 'Romania', privacyPolicy: true },
  {
    name: 'Russian Federation',
    value: 'Russian Federation',
    privacyPolicy: true
  },
  { name: 'Rwanda', value: 'Rwanda', privacyPolicy: false },
  { name: 'Saint Barthélemy', value: 'Saint Barthélemy', privacyPolicy: false },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    privacyPolicy: false
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
    privacyPolicy: false
  },
  { name: 'Saint Lucia', value: 'Saint Lucia', privacyPolicy: false },
  {
    name: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
    privacyPolicy: false
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
    privacyPolicy: false
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
    privacyPolicy: false
  },
  { name: 'Samoa', value: 'Samoa', privacyPolicy: false },
  { name: 'San Marino', value: 'San Marino', privacyPolicy: true },
  {
    name: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
    privacyPolicy: false
  },
  { name: 'Saudi Arabia', value: 'Saudi Arabia', privacyPolicy: false },
  { name: 'Senegal', value: 'Senegal', privacyPolicy: false },
  { name: 'Serbia', value: 'Serbia', privacyPolicy: true },
  { name: 'Seychelles', value: 'Seychelles', privacyPolicy: false },
  { name: 'Sierra Leone', value: 'Sierra Leone', privacyPolicy: false },
  { name: 'Singapore', value: 'Singapore', privacyPolicy: false },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)',
    privacyPolicy: false
  },
  { name: 'Slovakia', value: 'Slovakia', privacyPolicy: true },
  { name: 'Slovenia', value: 'Slovenia', privacyPolicy: true },
  { name: 'Solomon Islands', value: 'Solomon Islands', privacyPolicy: false },
  { name: 'Somalia', value: 'Somalia', privacyPolicy: false },
  { name: 'South Africa', value: 'South Africa', privacyPolicy: false },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
    privacyPolicy: false
  },
  { name: 'South Sudan', value: 'South Sudan', privacyPolicy: false },
  { name: 'Spain', value: 'Spain', privacyPolicy: true },
  { name: 'Sri Lanka', value: 'Sri Lanka', privacyPolicy: false },
  { name: 'Sudan', value: 'Sudan', privacyPolicy: false },
  { name: 'Suriname', value: 'Suriname', privacyPolicy: false },
  {
    name: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
    privacyPolicy: false
  },
  { name: 'Swaziland', value: 'Swaziland', privacyPolicy: false },
  { name: 'Sweden', value: 'Sweden', privacyPolicy: true },
  { name: 'Switzerland', value: 'Switzerland', privacyPolicy: true },
  {
    name: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
    privacyPolicy: false
  },
  { name: 'Tajikistan', value: 'Tajikistan', privacyPolicy: false },
  {
    name: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
    privacyPolicy: false
  },
  { name: 'Thailand', value: 'Thailand', privacyPolicy: false },
  { name: 'Timor-Leste', value: 'Timor-Leste', privacyPolicy: false },
  { name: 'Togo', value: 'Togo', privacyPolicy: false },
  { name: 'Tokelau', value: 'Tokelau', privacyPolicy: false },
  { name: 'Tonga', value: 'Tonga', privacyPolicy: false },
  {
    name: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
    privacyPolicy: false
  },
  { name: 'Tunisia', value: 'Tunisia', privacyPolicy: false },
  { name: 'Turkey', value: 'Turkey', privacyPolicy: true },
  { name: 'Turkmenistan', value: 'Turkmenistan', privacyPolicy: false },
  {
    name: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
    privacyPolicy: false
  },
  { name: 'Tuvalu', value: 'Tuvalu', privacyPolicy: false },
  { name: 'Uganda', value: 'Uganda', privacyPolicy: false },
  { name: 'Ukraine', value: 'Ukraine', privacyPolicy: true },
  {
    name: 'United Arab Emirates',
    value: 'United Arab Emirates',
    privacyPolicy: false
  },
  { name: 'United Kingdom', value: 'United Kingdom', privacyPolicy: true },
  { name: 'Uruguay', value: 'Uruguay', privacyPolicy: false },
  { name: 'Uzbekistan', value: 'Uzbekistan', privacyPolicy: false },
  { name: 'Vanuatu', value: 'Vanuatu', privacyPolicy: false },
  {
    name: 'Venezuela, Bolivarian Republic of',
    value: 'Venezuela, Bolivarian Republic of',
    privacyPolicy: false
  },
  { name: 'Viet Nam', value: 'Viet Nam', privacyPolicy: false },
  {
    name: 'Virgin Islands, British',
    value: 'Virgin Islands, British',
    privacyPolicy: false
  },
  {
    name: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
    privacyPolicy: false
  },
  { name: 'Western Sahara', value: 'Western Sahara', privacyPolicy: false },
  { name: 'Yemen', value: 'Yemen', privacyPolicy: false },
  { name: 'Zambia', value: 'Zambia', privacyPolicy: false },
  { name: 'Zimbabwe', value: 'Zimbabwe', privacyPolicy: false }
]
