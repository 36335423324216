// ================================================================================================

// DEPRECATED

// ================================================================================================

import { colorPalette } from './colorPalette'
import { typography } from './typography'

export const styleTheme = {
  ...colorPalette,
  ...typography
}
