// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-banking-tsx": () => import("./../../../src/pages/banking.tsx" /* webpackChunkName: "component---src-pages-banking-tsx" */),
  "component---src-pages-case-studies-clickup-js": () => import("./../../../src/pages/case-studies/clickup.js" /* webpackChunkName: "component---src-pages-case-studies-clickup-js" */),
  "component---src-pages-case-studies-gosite-tsx": () => import("./../../../src/pages/case-studies/gosite.tsx" /* webpackChunkName: "component---src-pages-case-studies-gosite-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-quadpay-js": () => import("./../../../src/pages/case-studies/quadpay.js" /* webpackChunkName: "component---src-pages-case-studies-quadpay-js" */),
  "component---src-pages-case-studies-quadpay-old-tsx": () => import("./../../../src/pages/case-studies/quadpay-old.tsx" /* webpackChunkName: "component---src-pages-case-studies-quadpay-old-tsx" */),
  "component---src-pages-case-studies-tetra-tsx": () => import("./../../../src/pages/case-studies/tetra.tsx" /* webpackChunkName: "component---src-pages-case-studies-tetra-tsx" */),
  "component---src-pages-case-studies-yotta-js": () => import("./../../../src/pages/case-studies/yotta.js" /* webpackChunkName: "component---src-pages-case-studies-yotta-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fintech-2-tsx": () => import("./../../../src/pages/fintech-2.tsx" /* webpackChunkName: "component---src-pages-fintech-2-tsx" */),
  "component---src-pages-fintech-tsx": () => import("./../../../src/pages/fintech.tsx" /* webpackChunkName: "component---src-pages-fintech-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what-we-do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-insight-tsx": () => import("./../../../src/templates/insight.tsx" /* webpackChunkName: "component---src-templates-insight-tsx" */),
  "component---src-templates-insights-tsx": () => import("./../../../src/templates/insights.tsx" /* webpackChunkName: "component---src-templates-insights-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

