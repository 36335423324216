module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBL93J9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"webuild","short_name":"webuild","start_url":"/","background_color":"#0E0E1B","theme_color":"#0E0E1B","display":"minimal-ui","icon":"src/static/images/favicons/master-310x310.png","icons":[{"src":"/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/favicons/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/favicons/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/favicons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/maskable-icon.png","sizes":"180x180","type":"image/png","purpose":"any maskable"},{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/apple-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9ba0242511ff5e53813faa831a6b8f75"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
